@import '../styles/variables';

.root {
  position: relative;
  display: block;
}

.dark {
  .seperator {
    &::after {
      content: '';
      background-color: var(--deep-purple);
    }
  }
  .tabs {
    background-color: var(--deep-purple);
    .tab {
      color: var(--white);
    }
  }
}

.seperator {
  position: relative;
  margin: 0 auto;
  height: 90px;
  @media (min-width: $view-lg) {
    height: 0;
  }
  &::after {
    content: '';
    position: absolute;
    top: 1px;
    left: 0;
    width: 100%;
    height: 90px;

    clip-path: polygon(70% 93%, 100% 0, 100% 100%, 0 100%, 0 0);
    @media (min-width: $view-lg) {
      clip-path: polygon(0% 15%, 35% 100%, 82% 13%, 103% 100%, 0% 100%);
      height: 130px;
      top: -130px;
    }
  }
  &::before {
    content: '';
    background-color: var(--bright-accent);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 90px;

    clip-path: polygon(70% 58%, 100% 0, 100% 100%, 0 100%, 0 0);
    @media (min-width: $view-lg) {
      clip-path: polygon(0% 0%, 35% 97%, 82% 0%, 101% 90%, 0% 120%);
      height: 130px;
      top: -130px;
    }
  }
}

.tabs {
  padding: 48px 24px 48px;
  display: block;
  margin: 0 auto;
  width: 100%;
  position: relative;
}

.tab {
  cursor: pointer;
  position: relative;
  font-weight: 700;
  &.active {
    &::after {
      content: '';
      position: absolute;
      bottom: -16px;
      left: 30px;
      width: 20px;
      height: 4px;
      background-color: var(--bright-accent);
    }
    .icon {
      color: var(--bright-accent);
    }
  }
  &::hover {
    &::after {
      content: '';
      position: absolute;
      bottom: -16px;
      left: 28px;
      width: 20px;
      height: 4px;
      background-color: var(--bright-accent);
    }
    .icon {
      color: var(--bright-accent);
    }
    &::after {
      content: '';
    }
  }
  &::after {
    content: '';
  }
}
.icon {
  font-family: 'athena-icons';
  margin-right: 8px;
  font-size: 20px;
}

.content {
  background-color: var(--deep-purple);
  margin-bottom: -32px;
  @media (min-width: $view-lg) {
    margin-bottom: -96px;
  }
}
.tempBlock {
  background-color: var(--bright-accent);
  max-width: 1440px;
  margin: 0 auto;
  height: 488px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 24px;
  h1 {
    text-align: center;
    color: white;
  }
}

.controlSection {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-bottom: 16px;
  padding: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), -5px 4px 16px rgba(0, 0, 0, 0.08);
  position: relative;
  z-index: 1;
  @media (min-width: $view-lg) {
    height: 488px;
    margin-bottom: 0;
    justify-content: space-between;
    padding: 64px 24px 64px 24px;

    &.marginRight {
      margin-right: 16px;
    }
    &.marginLeft {
      margin-left: 16px;
    }
  }
}

.contentSection {
  position: relative;
  z-index: 1;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), -5px 4px 16px rgba(0, 0, 0, 0.08);
  margin-bottom: 16px;
  @media (min-width: $view-lg) {
    height: 488px;
    padding-right: 24px;
    margin-bottom: 0;
  }
}

.columnRoot {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.tabSelector {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(121, 102, 133, 0.5);
  border-radius: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin: 8px 0;
  max-width: 1280px;
}

.verticalTabWrapper {
  width: 100%;
  margin-bottom: 16px;
}

.root .swiper {
  overflow: visible;
}
.root .swiperSlide {
  width: fit-content;
  overflow: visible;
}
