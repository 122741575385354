@import '../styles/variables';

.powerBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 4%), -5px 4px 16px rgb(0 0 0 / 8%);
  position: relative;
  z-index: 1;
  @media (min-width: $view-lg) {
    flex-direction: row;
    height: 488px;
  }
  .leftSection {
    background: #ffffff;
    height: 100%;
    width: 100%;
    padding: 24px 16px;

    @media (min-width: $view-lg) {
      width: 40%;
      display: block;
      position: absolute;
      height: 488px;
    }
  }

  .rightSection {
    width: 100%;
    height: 100%;
    background: radial-gradient(340.64% 86.54% at 71.87% -14.38%, #9600f1 0%, #3b015f 100%);
    padding: 24px 0;
    position: relative;

    @keyframes straightToDiagonal {
      0% {
        clip-path: polygon(100% 0, 49% 100%, 50% 9%, 0 100%, 0 0);
      }
      100% {
        clip-path: polygon(100% 0, 75% 25%, 35% 65%, 0 100%, 0 0);
      }
    }

    @keyframes DiagonalToStraight {
      0% {
        clip-path: polygon(100% 0, 75% 25%, 35% 65%, 0 100%, 0 0);
      }
      100% {
        clip-path: polygon(100% 0, 49% 100%, 50% 9%, 0 100%, 0 0);
      }
    }

    &:before {
      display: none;
      @media (min-width: $view-lg) {
        display: block;
      }
      content: '';
      position: absolute;
      left: 0px;
      top: 0;
      width: 224px;
      height: 100%;
      background-color: #210234;
      z-index: 1;
    }
    &.straight {
      &:before {
        animation: 0.3s straightToDiagonal forwards;
        animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
        clip-path: polygon(100% 0, 75% 25%, 35% 65%, 0 100%, 0 0);
        @media (min-width: $view-lg) {
          clip-path: polygon(0 0, 100% 0, 60% 40%, 40% 60%, 0 100%);
        }
        &:before {
          animation: 0.3s straightToDiagonal forwards;
          animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
          clip-path: polygon(100% 0, 75% 25%, 35% 65%, 0 100%, 0 0);
          @media (min-width: $view-lg) {
            clip-path: polygon(0 0, 100% 0, 60% 40%, 40% 60%, 0 100%);
          }
        }
      }
    }
    &.diagonal {
      &:before {
        animation: 0.3s DiagonalToStraight forwards;
        animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
        clip-path: polygon(100% 0, 49% 100%, 50% 9%, 0 100%, 0 0);
        @media (min-width: $view-lg) {
          clip-path: polygon(0 0, 100% 0, 30% 35%, 70% 65%, 0 100%);
        }
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 0px;
      background-color: white;
      width: 100%;
      height: 24px;
      z-index: 2;
    }

    .animation {
      top: -60px;
      left: 50%;
      transform: translateX(-50%) scale(0.5);
      position: absolute;
      z-index: 4;
      height: 148px;
      width: 148px;
      background-size: cover;
    }

    &.straight {
      .animation {
        background: center / contain no-repeat url('/next-assets/animations/sux2.webp');
      }
      &:after {
        animation: 0.3s straightToDiagonal forwards;
        animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
        clip-path: polygon(100% 0, 75% 25%, 35% 65%, 0 100%, 0 0);
        @media (min-width: $view-lg) {
          clip-path: polygon(0 0, 100% 0, 60% 40%, 40% 60%, 0 100%);
        }
        &:after {
          animation: 0.3s straightToDiagonal forwards;
          animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
          clip-path: polygon(100% 0, 75% 25%, 35% 65%, 0 100%, 0 0);
          @media (min-width: $view-lg) {
            clip-path: polygon(0 0, 100% 0, 60% 40%, 40% 60%, 0 100%);
          }
        }
      }
      .powerOnlySwiper {
        transform: translateY(-100%);
        opacity: 0;
        transition: transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28), opacity 0.3s ease;
      }
    }

    &.diagonal {
      .animation {
        background: center / contain no-repeat url('/next-assets/animations/pux2.webp');
      }
      &:after {
        animation: 0.3s DiagonalToStraight forwards;
        animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
        clip-path: polygon(100% 0, 49% 100%, 50% 9%, 0 100%, 0 0);
        @media (min-width: $view-lg) {
          clip-path: polygon(0 0, 100% 0, 30% 35%, 70% 65%, 0 100%);
        }
      }
      .powerOnlySwiper {
        transform: translateY(0);
        opacity: 1;
        transition: transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28), opacity 0.3s ease;
      }
    }

    @media (min-width: $view-lg) {
      width: calc(60% + 103px);
      display: block;
      position: absolute;
      height: 488px;
      right: 0;
      background: linear-gradient(90deg, #a245ec 0%, #a245ec 43.75%, #4c108c 100%);
      z-index: 3;
      @keyframes straightToDiagonal {
        0% {
          clip-path: polygon(80% 20%, 100% 0, 100% 100%, 0 100%, 20% 80%);
        }
        100% {
          clip-path: polygon(0 0, 100% 0, 60% 40%, 40% 60%, 0 100%);
        }
      }

      @keyframes DiagonalToStraight {
        0% {
          clip-path: polygon(0 0, 100% 0, 60% 40%, 40% 60%, 0 100%);
        }
        100% {
          clip-path: polygon(0 0, 100% 0, 30% 35%, 70% 65%, 0 100%);
        }
      }

      &:after {
        content: '';
        position: absolute;
        left: -1px;
        top: 0;
        background-color: white;
        width: 206px;
        height: 100%;
      }
      .animation {
        top: 50%;
        left: 24px;
        transform: translateY(-50%);
        position: relative;
        z-index: 3;
        height: 170px;
        width: 170px;
        background-size: cover;
      }

      &.diagonal {
        &:after {
          clip-path: polygon(0 0, 100% 0, 30% 35%, 70% 65%, 0 100%);
        }
        .powerOnlySwiper {
          transform: translateY(0);
          opacity: 1;
          transition: transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28), opacity 0.3s ease;
        }
      }
      &.straight {
        &:after {
          clip-path: polygon(0 0, 100% 0, 60% 40%, 40% 60%, 0 100%);
        }
        .powerOnlySwiper {
          transform: translateY(-100%);
          opacity: 0;
          transition: transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28), opacity 0.3s ease;
        }
      }
    }
  }
}

@media (min-width: $view-lg) {
  @keyframes straightToDiagonal {
    0% {
      clip-path: polygon(0 0, 100% 0, 30% 35%, 70% 65%, 0 100%);
    }
    100% {
      clip-path: polygon(0 0, 100% 0, 60% 40%, 40% 60%, 0 100%);
    }
  }

  @keyframes DiagonalToStraight {
    0% {
      clip-path: polygon(0 0, 100% 0, 60% 40%, 40% 60%, 0 100%);
    }
    100% {
      clip-path: polygon(0 0, 100% 0, 30% 35%, 70% 65%, 0 100%);
    }
  }
}

@keyframes sprite {
  from {
    background-position: 0px;
  }
  to {
    background-position: -7289px;
  }
}

@keyframes spriteBackwards {
  from {
    background-position: -7289px;
  }
  to {
    background-position: 0px;
  }
}

.leftContent {
  display: flex;
  flex-direction: column;
  width: 360px;
  margin: 0 auto;
  align-items: center;
  align-content: center;
  height: 100%;
  justify-content: center;
  max-width: calc(100vw - 64px);
  @media (min-width: $view-lg) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
    top: 0;
  }
}

.leftBottomBlock {
  display: none;
  @media (min-width: $view-lg) {
    display: block;
    margin-top: 16px;
  }
}

.powerUpSlider {
  width: 48px;
  height: 24px;
  @media (min-width: $view-lg) {
    width: 64px;
    height: 32px;
  }
  display: block;
  margin: 0 auto;
  position: relative;

  border-radius: 44.146px;
  cursor: pointer;

  .powerUp {
    background: #ffffff;
    border: 0.613139px solid #eaeaea;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.11), 0px 2px 4px rgba(0, 0, 0, 0.04), -5px 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: left 0.3s ease;
    @media (min-width: $view-lg) {
      height: 48px;
      width: 48px;
    }
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &.powerUpMode {
    background: linear-gradient(180.53deg, #d591f9 0.44%, #de026a 99.54%);
    .powerUp {
      left: 100%;
    }
  }
  &.straightUpMode {
    background: linear-gradient(220.11deg, rgba(255, 0, 159, 0.6) -9.93%, rgba(255, 0, 159, 0) 55.09%), #7200cb;
    .powerUp {
      left: 0;
    }
  }
}

.rightContent {
  margin-top: 24px;
  @media (min-width: $view-lg) {
    margin-top: 0;
    right: 0;
    width: calc(100% - 64px);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .swiperWrapper {
    margin-bottom: 8px;
  }

  .swiperSlide {
    background-color: white;
    width: fit-content;
    border-radius: 8px;
    padding: 4px;
  }

  .rateSection {
    @media (min-width: $view-lg) {
      margin-top: 48px;
      margin-bottom: 16px;
    }
    color: white;
    .straightUpRate {
      display: block;
      position: absolute;
      left: calc(50% - 16px);
      top: 0;
      transition: transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
    }
    .powerUpRate {
      display: block;
      position: absolute;
      left: calc(50% - 16px);
      top: 0;
      transition: transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
    }
    .percentage {
      position: absolute;
      left: 50%;
      transform: translateX(32px);
    }

    &.straightUp {
      .straightUpRate {
        transform: translate(-50%, 0);
      }
      .powerUpRate {
        transform: translate(-50%, -100%);
      }
    }
    &.powerUp {
      .straightUpRate {
        transform: translate(-50%, 100%);
      }
      .powerUpRate {
        transform: translate(-50%, 0);
      }
    }

    .rate {
      font-weight: 800;
      font-size: 40px;
      line-height: 40px;
      text-align: center;
      margin-bottom: 4px;
      height: 40px;
      overflow: hidden;
      position: relative;
      margin-top: 24px;
      @media (min-width: $view-lg) {
        margin-top: 0;
      }
    }
    .rateType,
    .rateTier {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.025em;
      color: #ffffff;
      text-align: center;
    }
  }
}

.powerUpText,
.straightUpText {
  margin-bottom: 8px;
  transition: transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28), opacity 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.powerUpOn {
  .powerUpText {
    transform: translateY(0%);
    opacity: 1;
  }
  .straightUpText {
    transform: translateY(100%);
    opacity: 0;
    position: absolute;
  }
}

.straightUpOn {
  .powerUpText {
    transform: translateY(-100%);
    opacity: 0;
    position: absolute;
  }
  .straightUpText {
    transform: translateY(0);
    opacity: 1;
  }
}

.desktopSwiper {
  display: none;
  @media (min-width: $view-lg) {
    display: block;
  }
}

.mobileSwiper {
  display: block;
  @media (min-width: $view-lg) {
    opacity: 0;
    position: absolute;
  }
}

.mobilePowerUpButton {
  margin-top: 24px;
  display: block;
  padding-bottom: 24px;
  @media (min-width: $view-lg) {
    display: none;
  }
}

.desktopCta {
  display: none;
  @media (min-width: $view-lg) {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
}
.mobileCta {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
  @media (min-width: $view-lg) {
    display: none;
  }
}

.desktop {
  display: none;
  @media (min-width: $view-lg) {
    display: block;
  }
}

.mobile {
  display: block;
  @media (min-width: $view-lg) {
    display: none;
  }
}
