@import '../styles/variables';

.rates {
  display: flex;
  flex-direction: row;
}
.mobile-left {
  justify-content: flex-start;
}
.mobile-center {
  justify-content: center;
}
.mobile-right {
  justify-content: flex-end;
}
@media (min-width: $view-lg) {
  .desktop-left {
    justify-content: flex-start;
  }
  .desktop-center {
    justify-content: center;
  }
  .desktop-right {
    justify-content: flex-end;
  }
}

.rate,
.number {
  margin-bottom: 4px;
}

.disclaimer {
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: var(--grey-bright);
  opacity: 0.7;
}

// .rate p {
//   font-weight: 600;
//   font-size: 14px;
//   line-height: 14px;
//   margin-bottom: 4px;
// }

.title > p {
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  @media (min-width: $view-lg) {
    font-size: 14px;
    line-height: 14px;
  }
}

.rate > p {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  @media (min-width: $view-lg) {
    font-size: 14px;
    line-height: 14px;
  }
}

.divider {
  display: block;
  align-self: center;
  &:after {
    padding: 8px;
    content: '';
    @media (min-width: $view-lg) {
      color: #53cb5f;
      font-size: 18px;
      font-family: 'athena-icons';
      content: 'n';
    }
  }
}
