@import '../styles/variables';

.root {
  width: 100%;
  transition: color 0.3s linear;
  padding: 0 8px;
  margin-bottom: 4px;

  * {
    line-height: 24px;
  }
  cursor: pointer;
}

.bg {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--deep-purple);
  &:hover {
    color: var(--white);
    background-color: var(--bright-purple);
  }
  padding: 0 8px;
  border-radius: 4px;
}

.textContent {
  display: flex;
  flex-direction: row;
  line-height: 32px;
}

.icon {
  position: relative;
  width: 20px;
  height: 20px;
}

.arrow {
  line-height: 30px;
  height: 32px;
  margin: 0;
  padding-right: 8px;
}

.dark {
  color: var(--deep-purple);
}

.light {
  color: var(--deep-purple);
}

.text {
  justify-content: flex-start;
  font-weight: 700;
  padding-left: 8px;
}

.active {
  * {
    color: var(--white);
  }

  background-color: var(--deep-purple);
}
