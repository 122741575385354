@import '../styles/variables';

.root {
  position: relative;
  padding: 24px 16px 40px 16px;
  width: 100%;
  height: 100%;

  @media (min-width: $view-lg) {
    padding: 48px 48px 48px 48px;
    &.fixedHeight {
      height: 697px;
      .rates {
        position: absolute;
        margin-bottom: none;
      }

      .block {
        width: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-bottom: none;
      }
    }
  }
}

.container {
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  @media (min-width: $view-lg) {
    display: block;
    padding-bottom: 148px;
    &.paddingTop {
      padding-top: 148px;
    }
  }
}

.rates {
  margin-bottom: 36px;
  display: block;
}

.block {
  width: 100%;
  position: static;
  margin-bottom: 34px;
  @media (min-width: $view-lg) {
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
  }
}

.head {
  position: relative;
}

.trustpilot {
  margin-top: 16px;
  width: 100px;
  height: 20px;
  @media (min-width: $view-lg) {
    bottom: 95%;
    left: 90%;
    z-index: 1;
    position: absolute;
  }
  padding-bottom: 48px;
}

.mobileOnly {
  display: block;
  @media (min-width: $view-lg) {
    display: none;
  }
}

.desktopOnly {
  display: none;
  @media (min-width: $view-lg) {
    display: block;
  }
}

.right {
  position: relative;
  width: 50%;
  height: 100%;
  left: 50%;
}

.img {
  display: none;
  @media (min-width: $view-lg) {
    display: block;
    position: absolute;
    top: 50%;
    right: 25%;
    transform: translate(50%, -50%);
  }
}

.visible {
  display: block;
  cursor: pointer;
  @media (min-width: $view-lg) {
    position: absolute;
    top: 50%;
    right: 25%;
    transform: translate(50%, -50%);
  }
}

.cta-left {
  align-self: flex-start;
}

.cta-center {
  align-self: center;
}

.cta-left {
  align-self: right;
}

.imageDesktop {
  display: none;
  @media (min-width: $view-lg) {
    display: block;
  }
}

.imageMobile {
  display: block;
  @media (min-width: $view-lg) {
    display: none;
  }
}
